document.addEventListener('alpine:init', () => {
    const suggestions = {
        cities: [],
        areas: [],
        addresses: [],
        citycount = 0,
        areacount = 0,
        addresscount = 0,
        totalcount = 0,
        q: '',
        loading: true,
        getSuggestions() {
            if (this.q) {
                awaitFetchGet(`/umbraco/api/suggestion/getsuggestions?q=${this.q}`)
                    .then(data => {
                        this.loading = false;

                        this.addresses = data.addresses;
                        this.addresscount = this.addresses.length;

                        this.areas = data.areas;
                        this.areacount = this.areas.length;

                        this.cities = data.cities;
                        this.citycount = this.cities.length;

                        this.totalcount = this.citycount + this.areacount + this.addresscount; 
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
            }
        }
    };

    window.suggestions = suggestions;
});
