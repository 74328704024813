async function awaitFetchGet(url) {
    const response = await fetch(url, {
        method: "GET",
        cache: 'force-cache',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'x-requested-with': 'XmlHttpRequest'
        }
    })

    return await response.json();
}

async function awaitFetchPost(url, formBody) {
    const response = await fetch(url, {
        method: "POST",
        cache: 'force-cache',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'x-requested-with': 'XmlHttpRequest',
        },
        body: formBody
    })

    return await response.json();
}
