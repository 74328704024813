document.addEventListener('alpine:init', () => {
    const addToCartForm = {
        loading: false,
        deliverydate: null,
        deliveryDateErrorMessage: '',
        addToCart(dispatch) {
            this.loading = true;
            this.deliveryDateErrorMessage = '';

            let d = document.getElementById("datepicker").value;
            console.log(d);

            var formBody = new URLSearchParams(new FormData(this.$refs.addtocartform)).toString();
            console.log(formBody);
            
            awaitFetchPost("/umbraco/surface/cartsurface/addtocart", formBody).then(data => {
                console.log(data);
                if (data.error) {
                    this.loading = false;
                } else {
                    if (data.DeliveryDate && data.DeliveryDate.errors.length > 0) {
                        this.deliveryDateErrorMessage = data.DeliveryDate.errors[0].errorMessage;
                    } else {
                        dispatch('updatecart', { smallCart: data, cartOpen: true })
                        console.log("order");
                        console.log(JSON.stringify(data));

                        gtag("event", "add_to_cart", {
                            currency: "DKK",
                            value: data.total,
                            items: [
                                {
                                    item_id: data.id,
                                    item_name: "Stan and Friends Tee",
                                    affiliation: "Google Merchandise Store",
                                    coupon: "SUMMER_FUN",
                                    discount: 2.22,
                                    index: 0,
                                    location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                                    price: 9.99,
                                    quantity: 1
                                }
                            ]
                        });
                    }
                    this.loading = false;
                }

            })
            .catch(() => {
                this.loading = false;
            });
        },
        clearErrorMessage() {
            this.deliveryDateErrorMessage = '';
        },
        getVariant() {
            this.loading = true;

            awaitFetchGet(`/umbraco/api/variant/getvariant?productReference=${this.productreference}&variantReference=${this.variantreference}`).then(data => {
                    this.productprice = data.price.withTax;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
            }
    };

    const updateCartForm = {
        loading: false,
        updateCart(event, orderlineId) {
            this.loading = true;

            var formBody = new URLSearchParams(new FormData(this.$root)).toString();

            awaitFetchPost("/umbraco/surface/cartsurface/updatecart", formBody).then(data => {
                this.cart = data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        },
        removeFromCart(orderlineId) {
            this.loading = true;

            var formData = new FormData(this.$root);
            formData.append("OrderLineId", orderlineId);
            var formBody = new URLSearchParams(formData).toString();

            awaitFetchPost("/umbraco/surface/cartsurface/removefromcart", formBody).then(data => {
                this.cart = data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        }
    };

    window.addToCartForm = addToCartForm;
    window.updateCartForm = updateCartForm;

    Alpine.data("removeFromCartForm", () => ({
        loading: false,
        removeFromCart(dispatch) {
            this.loading = true;

            var formData = new FormData();
            formData.append("OrderLineId", this.$root.OrderLineId.value);

            var formBody = new URLSearchParams(new FormData(this.$root)).toString();

            awaitFetchPost("/umbraco/surface/cartsurface/removefromcart", formBody).then(data => {
                dispatch('updatecart', { smallCart: data, cartOpen: true })
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
        }
    }));
});
